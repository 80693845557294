import { toggleClass, debounce, addClassToElement, removeClassFromElement } from "./ui-utilities";
import { LazyImageLoader } from "./lazy-images";

(function () {
    let xhr = null;
    let ignoreChanges = false;
    const imageLoader = new LazyImageLoader();

    function initializeSearchTaxonomyBlock() {
        document.querySelectorAll(".block.search-taxonomy").forEach((el) => {
            let searchInputElement = el.querySelector(".filter-items input.fuzzy-search");
            if (searchInputElement !== null) {
                searchInputElement.addEventListener("keyup", debounce(handleSearchInputChange, 500, false));
            }

            let categoryFilterOne = el.querySelector(".filter-items .fuzzy-search-opt1");
            if (categoryFilterOne !== null) {
                categoryFilterOne.addEventListener("change", debounce(handleSelectionChange, 100, false));
            }

            let categoryFilterTwo = el.querySelector(".filter-items .fuzzy-search-opt2");
            if (categoryFilterTwo !== null) {
                categoryFilterTwo.addEventListener("change", debounce(handleSelectionChange, 100, false));
            }

            let clearFilterButton = document.getElementById("clear-filter-button-" + el.dataset.contentid);
            if (clearFilterButton !== null) {
                clearFilterButton.addEventListener("click", clearFilters);
            }

            bindPagingEvents(el.dataset.contentid, "add");
        });
    }

    function handleSearchInputChange(event) {
        if (ignoreChanges) return;
        let searchString = event.target.value;
        let blockElement = event.target.closest(".block.search-taxonomy");
        let blockId = blockElement.dataset.contentid;
        resetAllButThis(event.target, blockId);
        processFilterState(blockId);
        doSearch(searchString, blockId);
    }

    function handleSelectionChange(event) {
        if (ignoreChanges) return;
        let blockElement = event.target.closest(".block.search-taxonomy");
        let blockId = blockElement.dataset.contentid;
        resetAllButThis(event.target, blockId);
        processFilterState(blockId);
        doFilter(event.target.value, blockId);
    }

    function doSearch(searchTerm, blockId) {
        getResults(blockId, 1);
    }

    function resetAllButThis(element, blockId) {
        ignoreChanges = true;
        let fuzzySearch = document.getElementById("fuzzy-search-" + blockId);
        let selection1 = document.getElementById("fuzzy-search-opt1-" + blockId);
        let selection2 = document.getElementById("fuzzy-search-opt2-" + blockId);
        if (element !== fuzzySearch) {
            fuzzySearch.value = "";
        }

        if (element !== selection1 && selection1 !== null) {
            selection1.selectedIndex = 0;
        }

        if (element !== selection2 && selection2 !== null) {
            selection2.selectedIndex = 0;
        }
        ignoreChanges = false;
    }

    function doFilter(categoryId, blockId) {
        getResults(blockId, 1);
    }

    function getResults(blockId, pageNumber) {
        let searchTerm = "";
        let categoryFilterId = -1;
        let itemsPerPage = 10;

        // we can get the state of everything right out of the existing controls
        let blockRoot = document.getElementById("taxonomy-block-" + blockId);
        let fuzzySearch = document.getElementById("fuzzy-search-" + blockId);
        let selection1 = document.getElementById("fuzzy-search-opt1-" + blockId);
        let selection2 = document.getElementById("fuzzy-search-opt2-" + blockId);
        if (fuzzySearch !== null && fuzzySearch.value !== null) {
            searchTerm = fuzzySearch.value;
        }

        if (selection1 !== null && selection1.selectedIndex !== 0) {
            categoryFilterId = selection1.value;
        }

        if (selection2 !== null && selection2.selectedIndex !== 0) {
            categoryFilterId = selection2.value;
        }

        itemsPerPage = getCurrentItemsPerPage(blockId);

        bindPagingEvents(blockId, "remove");
        manageCardLinks(false);
        if (typeof (xhr) !== "undefined" && xhr !== null) {
            xhr.abort();
        }

        xhr = new XMLHttpRequest();

        // let's get the pageId if it's on the element
        let pageId = (blockRoot.dataset.pageid) !== null ? blockRoot.dataset.pageid : "";
        let ajaxRoute = blockRoot.dataset.ajaxRoute;

        let taxonomySearchRequest = {
            CategoryFilterId: categoryFilterId,
            SearchTerm: searchTerm,
            BlockId: blockId,
            PageNumber: pageNumber,
            ItemsPerPage: itemsPerPage,
            PageId: pageId
        };

        let itemsList = document.getElementById("cat-list-" + blockId);
        toggleClass(itemsList, "fetching");
        let taxonomySearchRequestJson = JSON.stringify(taxonomySearchRequest);

        xhr.open('POST', ajaxRoute);
        xhr.setRequestHeader('Content-Type', 'application/json; charset=utf-8');
        xhr.addEventListener("abort", function (event) { console.log("aborted"); });
        xhr.onload = function () {
            if (xhr.status === 200) {
                //replace the child under the results HTML element
                processResults(blockId, xhr.responseText);
            }
            else if (xhr.status === 0) {
                console.log("I was aborted");
            }
            else if (xhr.status !== 200) {
                console.log('Request failed.  Returned status of ' + xhr.status);
            }
            let itemsList = document.getElementById("cat-list-" + blockId);
            toggleClass(itemsList, "fetching");
        };
        xhr.send(taxonomySearchRequestJson);
    }

    function bindPagingEvents(blockId, addOrRemoveEvent) {
        // if we don't have a pager, then don't bother
        let pagingElement = document.getElementById("taxonomy-pager-" + blockId);
        if (pagingElement === null) return;

        let currentPageNumber = pagingElement.dataset.currentpage;
        let totalPageCount = pagingElement.dataset.pagecount;

        let pageCountSelect = document.getElementById("pagingSelect-" + blockId);
        let lastButton = document.getElementById("last-button-" + blockId);
        let firstButton = document.getElementById("first-button-" + blockId);
        let nextButton = document.getElementById("next-button-" + blockId);
        let prevButton = document.getElementById("previous-button-" + blockId);
        let pageNumberInput = document.getElementById("pagingtext-" + blockId);

        if (pageCountSelect !== null) {
            if (addOrRemoveEvent === "add") {
                pageCountSelect.addEventListener("change", pageCountSelectChange)
            }
            else {
                pageCountSelect.removeEventListener("change", pageCountSelectChange)
            }
        }

        if (lastButton !== null) {
            if (addOrRemoveEvent === "add") {
                if (currentPageNumber !== totalPageCount) {
                    lastButton.addEventListener("click", pagingButtonClick);
                }
                else {
                    lastButton.setAttribute("disabled", true);
                }
            }
            else {
                lastButton.removeEventListener("click", pagingButtonClick);
            }
        }

        if (firstButton !== null) {
            if (addOrRemoveEvent === "add") {
                if (currentPageNumber !== 1) {
                    firstButton.addEventListener("click", pagingButtonClick);
                }
                else {
                    firstButton.setAttribute("disabled", true);
                }
            }
            else {
                firstButton.removeEventListener("click", pagingButtonClick);
            }
        }

        if (nextButton !== null) {
            if (addOrRemoveEvent === "add") {
                if (currentPageNumber !== totalPageCount) {
                    nextButton.addEventListener("click", pagingButtonClick);
                }
                else {
                    nextButton.setAttribute("disabled", true);
                }
            }
            else {
                nextButton.removeEventListener("click", pagingButtonClick);
            }
        }

        if (prevButton !== null) {
            if (addOrRemoveEvent === "add") {
                if (currentPageNumber !== 1) {
                    prevButton.addEventListener("click", pagingButtonClick);
                }
                else {
                    prevButton.setAttribute("disabled", true);
                }
            }
            else {
                prevButton.removeEventListener("click", pagingButtonClick);
            }
        }

        if (pageNumberInput !== null) {
            if (addOrRemoveEvent === "add") {
                pageNumberInput.addEventListener("keyup", pageNumberInputKeyUp);
                pageNumberInput.addEventListener("change", pageNumberInputChange);
            }
            else {
                pageNumberInput.removeEventListener("keyup", pageNumberInputKeyUp);
                pageNumberInput.removeEventListener("change", pageNumberInputChange);
            }
        }
    }

    function manageCardLinks(boolBindEvents) {
        let blogCards = document.querySelectorAll('[data-cardlink]');
        if (boolBindEvents) {
            blogCards.forEach(el => {
                el.addEventListener("click", doCardLink);
            });
        }
        else {
            blogCards.forEach(el => {
                el.removeEventListener("click", doCardLink);
            });
        }
    }

    function doCardLink(evt) {
        if (evt.target !== null && evt.target.nodeName != "A") {
            // if we've not clicked on a link but just somewhere in the card, then we can
            // navigate to the blog page... otherwise, we'll just let the link do it's normal thing
            // need to find the closest cardlink
            let el = evt.target.closest("[data-cardlink]");
            window.location = el.dataset.cardlink;
        }
    }

    function pageCountSelectChange(event) {
        let blockId = getClosestBlockId(event.target);
        getResults(blockId, 1);
    }

    function pageNumberInputKeyUp(event) {
        let blockId = getClosestBlockId(event.target);
        let pageCount = getPageCount(blockId);
        let inputValue = Math.floor(event.target.value);

        if (inputValue > pageCount) {
            event.target.value = pageCount;
        }
    }

    function pageNumberInputChange(event) {
        let blockId = getClosestBlockId(event.target);
        let pageCount = getPageCount(blockId);
        // if the value is different and not zero
        let inputValue = Math.floor(event.target.value);
        let currentValue = getCurrentPageNumber(blockId);
        if (inputValue > 0 && inputValue <= pageCount) {
            if (inputValue !== currentValue) {
                getResults(blockId, inputValue);
            }
        } else {
            event.target.value = currentValue;
        }
    }

    function getClosestBlockId(element) {
        let blockElement = element.closest(".block.search-taxonomy");
        return blockElement.dataset.contentid;
    }

    function getCurrentItemsPerPage(blockId) {
        let pagingSelect = document.getElementById("pagingSelect-" + blockId);
        if (pagingSelect !== null) {
            return document.getElementById("pagingSelect-" + blockId).value;
        } else {
            // let's just use the default value configured for the block
            let rootBlock = document.getElementById("taxonomy-block-" + blockId);
            if (rootBlock !== null) {
                if (rootBlock.dataset.defaultItemsperpage !== null) {
                    return rootBlock.dataset.defaultItemsperpage;
                }
            }
            return 25;
        }
    }

    function getCurrentPageNumber(blockId) {
        let pagingElement = document.getElementById("taxonomy-pager-" + blockId);
        if (pagingElement === null) return;
        let pageNumber = Math.floor(pagingElement.dataset.currentpage);
        return pageNumber;
    }

    function getPageCount(blockId) {
        let pagingElement = document.getElementById("taxonomy-pager-" + blockId);
        if (pagingElement === null) return;
        let pageCount = Math.floor(pagingElement.dataset.pagecount);
        return pageCount;
    }

    function pagingButtonClick(event) {
        let blockId = getClosestBlockId(event.target);
        let btnElement = event.target.closest("button");
        let currentPageNumber = getCurrentPageNumber(blockId);
        let pageCount = getPageCount(blockId);
        let newPageNumber = 1;
        switch (btnElement.dataset.pagingAction) {
            case "next":
                // current page plus 1
                newPageNumber = currentPageNumber + 1;
                break;
            case "prev":
                // current page -1
                newPageNumber = currentPageNumber - 1;
                break;
            case "first":
                // page 1 do do nothing

                break;
            case "last":
                // page max
                newPageNumber = pageCount;
                break;
        }

        getResults(blockId, newPageNumber);
    }

    function processResults(blockId, resultText) {
        // just need to find the block and remove the current list and replace
        let domId = "cat-items-" + blockId;
        let listRoot = document.getElementById(domId);
        listRoot.innerHTML = resultText;
        bindPagingEvents(blockId, "add");
        manageCardLinks(true);
        imageLoader.processImages();
    }

    function processFilterState(blockId) {
        // if any of the filters are applied, then we need to show a button so the user can easily clear the filters
        let fuzzySearch = document.getElementById("fuzzy-search-" + blockId);
        let selection1 = document.getElementById("fuzzy-search-opt1-" + blockId);
        let selection2 = document.getElementById("fuzzy-search-opt2-" + blockId);
        let clearFilterLabel = document.getElementById("clear-filter-label-" + blockId);

        let fuzzySearchIsEmpty = ((fuzzySearch.value !== null && fuzzySearch.value === "") || fuzzySearch === null) ? true : false;
        let selection1IsEmpty = ((selection1 !== null && selection1.selectedIndex === 0) || selection1 === null) ? true : false;
        let selection2IsEmpty = ((selection2 !== null && selection2.selectedIndex === 0) || selection2 === null) ? true : false;

        if (fuzzySearchIsEmpty && selection1IsEmpty && selection2IsEmpty) {
            // hide the clear filter button
            removeClassFromElement(clearFilterLabel, "active");
        } else {
            // show the clear filter button
            addClassToElement(clearFilterLabel, "active");
        }
    }

    function clearFilters(event) {
        let blockId = getClosestBlockId(event.target);
        resetAllButThis(null, blockId);
        processFilterState(blockId);
        getResults(blockId, 1);
    }

    if (document.readyState === "complete" || document.readyState === "interactive") {
        setTimeout(1, initializeSearchTaxonomyBlock);
    } else {
        document.addEventListener("DOMContentLoaded", initializeSearchTaxonomyBlock);
    }
})();